import { v4 } from 'uuid';

import type { OrderCartItems, User, Order, OrderParticipants } from '@/payload/payload-types';
import { extractId } from '@/shared/extract-id';
import { Flatten } from '../../_utilities/typescript';
import { generateCartOrderItemPrices } from '@/payload/utilities/generate-cart-order-item-prices';
import { PaymentMethod } from '@/payload/types/every-pay';
import { PublicDiscountCode, UserCartPartialDiscountCode } from '@/shared/typescript';
import {
	MULTIPLE_PAYER_FIRST_INTERNAL_ID,
	MULTIPLE_PAYER_SECOND_INTERNAL_ID,
} from '../../_components/Forms/multiple-payers';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';
export type CartItem = NonNullable<Flatten<OrderCartItems>>;

export type PaymentDetails = NonNullable<Order['paymentDetails']>;
export type PaymentDetail = NonNullable<Flatten<Order['paymentDetails']>>;
export type OrderParticipant = NonNullable<Flatten<OrderParticipants>>;
export type PayerType = PaymentDetail['payerType'] | 'multiple-payers';

export type CartStateType = UserCartPartialDiscountCode & {
	payerType?: PayerType;
	paymentType?: PaymentDetail['paymentType'];
	paymentDetails?: PaymentDetails;
	cartError?: string; // eg not enough spots/quantity remaining
	activeOrder?: Order | null;
	hasInitialSync?: boolean;
	hasChosenInstalmentPayments?: boolean;
	everyPayPaymentMethodSource?: PaymentMethod['source'] | null | undefined;
	isValidPaymentDetailsMap?: {
		isValid: boolean;
		paymentDetailId: string;
		errors?: FieldErrors;
	}[];
	isValidPaymentDetails?: boolean;
};
export type FlattenedCartStateType = Omit<CartStateType, 'discountCode'> & {
	discountCode?: string | null;
};
type CartAction =
	| {
			payload: CartItem;
			type: 'ADD_ITEM';
	  }
	| {
			payload: CartStateType;
			type: 'MERGE_CART';
	  }
	| {
			payload: CartStateType;
			type: 'SET_CART';
	  }
	| {
			payload: CartItem;
			type: 'DELETE_ITEM';
	  }
	| {
			type: 'CLEAR_CART';
	  }
	| {
			type: 'SET_PAYMENT_DETAILS';
			payload: {
				paymentDetails: NonNullable<PaymentDetails>;
				isMultiplePayers?: boolean;
			};
	  }
	| {
			type: 'SET_IS_VALID_PAYMENT_DETAILS';
			payload: { isValid: boolean; paymentDetailId: string; errors?: FieldErrors };
	  }
	| {
			type: 'SET_PAYER_TYPE';
			payload: PaymentDetail['payerType'] | 'multiple-payers';
	  }
	| {
			type: 'SET_ACTIVE_ORDER';
			payload?: Order | null;
	  }
	| {
			type: 'SET_DISCOUNT_CODE_TEXT';
			payload?: string | null;
	  }
	| {
			type: 'CLEAR_DISCOUNT_CODE';
	  }
	| {
			type: 'SET_HAS_CHOSEN_INSTALMENT_PAYMENTS';
			payload: boolean;
	  }
	| {
			type: 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT';
			payload: Order['chosenInstalmentPaymentsCount'];
	  }
	| {
			type: 'UPDATE_PARTICIPANT';
			payload: {
				participant: OrderParticipant;
				courseversionId: string;
			};
	  }
	| {
			type: 'SET_EVERYPAY_PAYMENT_METHOD_SOURCE';
			payload: PaymentMethod['source'] | null | undefined;
	  }
	| {
			type: 'SET_PAYMENT_TYPE';
			payload: PaymentDetail['paymentType'];
	  }
	| {
			type: 'SET_INITIAL_SYNC';
			payload: boolean;
	  };

const generateParticipantsToCartItems = (cartItems: OrderCartItems): OrderCartItems | null => {
	if (cartItems?.length) {
		return cartItems.map(cartItem => {
			let participants: OrderParticipants = cartItem.participants || [];
			const emptyParticipant: OrderParticipant = {
				firstName: null,
				lastName: null,
				employer: null,
				profession: null,
				email: null,
				phoneNumber: null,
				personalIdNumber: null,
				isOwnerUser: false,
			};

			if (!cartItem.quantity) {
				return {
					...cartItem,
					participants: [],
				};
			}

			const participantsCount = cartItem.participants?.length;
			if (!participantsCount) {
				participants = Array.from({ length: cartItem.quantity || 0 }, () => {
					return {
						...emptyParticipant,
						// temporary id, this will change when actually saved to payload
						id: v4(),
					};
				});
			} else if (participantsCount !== cartItem.quantity) {
				// remove participants
				const remainder = Math.abs(participantsCount - cartItem.quantity);

				if (participantsCount > cartItem.quantity) {
					participants.splice(participantsCount - remainder, remainder);
				} else if (participantsCount < cartItem.quantity) {
					// add participants
					participants = [
						...participants,
						...Array.from({ length: remainder }, () => {
							return {
								...emptyParticipant,
								// temporary id, this will change when actually saved to payload
								id: v4(),
							};
						}),
					];
				}
			}
			return {
				...cartItem,
				participants,
			};
		});
	}

	return null;
};

export const cartDefaultValues: CartStateType = {
	cartItems: [],
	paymentDetails: [],
	activeOrder: undefined,
	hasInitialSync: false,
	discountCode: undefined,
	payerType: 'personal',
	isValidPaymentDetailsMap: [],
	discountCodeText: null,
	chosenInstalmentPaymentsCount: null,
	hasChosenInstalmentPayments: false,
	everyPayPaymentMethodSource: null,
	paymentType: null,
	isValidPaymentDetails: undefined,
};

export const cartReducer = (state: CartStateType, action: CartAction): CartStateType => {
	switch (action.type) {
		case 'SET_CART': {
			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: action.payload.cartItems,
				discountCode: action.payload.discountCode,
				chosenInstalmentPaymentsCount: action.payload.chosenInstalmentPaymentsCount,
			});
			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...action.payload,
				cartItems,
				hasInitialSync: true,
			};
		}

		case 'SET_INITIAL_SYNC': {
			return {
				...state,
				hasInitialSync: action.payload,
			};
		}

		case 'CLEAR_DISCOUNT_CODE': {
			return {
				...state,
				cartItems: generateCartOrderItemPrices({
					items: state.cartItems,
					discountCode: null,
					chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
				}),
				discountCode: null,
				discountCodeText: '',
			};
		}

		case 'MERGE_CART': {
			const { payload: incomingCart } = action;

			const existingItems = (state?.cartItems || []).filter(Boolean);
			const incomingItems = (incomingCart?.cartItems || []).filter(Boolean);

			const syncedItems = [...existingItems, ...incomingItems].reduce(
				(acc: CartItem[], cartItem) => {
					if (!cartItem.orderedCollection) {
						return acc;
					}

					// remove duplicates
					const itemId =
						typeof cartItem.orderedCollection.value === 'string'
							? cartItem.orderedCollection.value
							: cartItem?.orderedCollection?.value?.id;
					const itemType = cartItem.orderedCollection.relationTo;

					const isNotAvailableForPurchase =
						typeof cartItem?.orderedCollection.value === 'string' ||
						!cartItem?.orderedCollection.value?.isAvailableForPurchase;

					// if (isNotAvailableForPurchase) {
					// 	// logToPayload(
					// 	// 	`Cant merge courseversion ${typeof cartItem?.orderedCollection.value === 'string' ? cartItem?.orderedCollection.value : cartItem?.orderedCollection.value.id} to cart because unavailable or bad depth?`,
					// 	// );
					// 	return acc;
					// }

					const indexInAcc = acc.findIndex(_cartItem => {
						const _item = _cartItem?.orderedCollection;

						if (!_item) {
							return false;
						}

						return (
							(typeof _item.value === 'string'
								? _item.value === itemId
								: _item?.value.id === itemId) && itemType === _item.relationTo
						);
					});

					if (indexInAcc > -1) {
						const incomingItem = incomingItems.find(
							incoming =>
								extractId(incoming.orderedCollection.value) ===
								extractId(cartItem.orderedCollection.value),
						);

						let updates: Partial<CartItem> = {};
						if (incomingItem) {
							// make sure to add here incoming data that can change from server!!! eg discount code stuff
							updates = {
								priceWithoutVAT: incomingItem.priceWithoutVAT,
								price: incomingItem.price,
								VAT: incomingItem.VAT,
								discountedPriceWithoutVAT: incomingItem.discountedPriceWithoutVAT,
								discountedPrice: incomingItem.discountedPrice,
								discountedVAT: incomingItem.discountedVAT,
								discountCodeApplied: incomingItem.discountCodeApplied,
								discountedPriceReduction: incomingItem.discountedPriceReduction,
								discountedPriceReductionTotal:
									incomingItem.discountedPriceReductionTotal,
								discountedInstalmentPriceReduction:
									incomingItem.discountedInstalmentPriceReduction,
								discountCodePriceReduction: incomingItem.discountCodePriceReduction,
								participants: incomingItem.participants,
							};
						}

						acc[indexInAcc] = {
							...acc[indexInAcc],
							...updates,
							// customize the merge logic here, e.g.:
							// quantity: acc[indexInAcc].quantity + item.quantity
						};
					} else {
						acc.push(cartItem);
					}

					return acc;
				},
				[],
			);

			return {
				...state,
				discountCode: incomingCart?.discountCode || incomingCart.activeOrder?.discountCode,
				discountCodeText:
					incomingCart?.discountCodeText || incomingCart.activeOrder?.discountCodeText,
				chosenInstalmentPaymentsCount: incomingCart?.chosenInstalmentPaymentsCount,
				hasChosenInstalmentPayments: Boolean(
					incomingCart?.chosenInstalmentPaymentsCount &&
						incomingCart.chosenInstalmentPaymentsCount > 1,
				),
				hasInitialSync: true,
				cartItems: syncedItems,
			};
		}

		case 'SET_PAYMENT_DETAILS': {
			const { payload } = action;
			const { paymentDetails, isMultiplePayers } = payload;
			let updatedPaymentDetails = [...paymentDetails];

			if (isMultiplePayers) {
				const existingMultiplePayerPaymentDetails = state.paymentDetails?.filter(
					_paymentDetail =>
						_paymentDetail.id === MULTIPLE_PAYER_FIRST_INTERNAL_ID ||
						_paymentDetail.id === MULTIPLE_PAYER_SECOND_INTERNAL_ID,
				);

				// if its multiple payers we need to find the payment detail with the same id and update it or add it, remove the rest
				if (existingMultiplePayerPaymentDetails?.length) {
					updatedPaymentDetails = _.uniqBy(
						[...paymentDetails, ...existingMultiplePayerPaymentDetails],
						'id',
					);
				}
			}

			return {
				...state,
				paymentDetails: updatedPaymentDetails,
			};
		}

		case 'SET_IS_VALID_PAYMENT_DETAILS': {
			let isValidPaymentDetailsMap: {
				paymentDetailId: string;
				isValid: boolean;
				errors?: FieldErrors;
			}[] = [];

			if (state.payerType === 'multiple-payers') {
				isValidPaymentDetailsMap = [
					MULTIPLE_PAYER_FIRST_INTERNAL_ID,
					MULTIPLE_PAYER_SECOND_INTERNAL_ID,
				].map(paymentDetailId => {
					const updatedPaymentDetail = state.isValidPaymentDetailsMap?.find(
						_paymentDetail => paymentDetailId === action.payload.paymentDetailId,
					);
					const existingPaymentDetail = state.isValidPaymentDetailsMap?.find(
						_paymentDetail => paymentDetailId === _paymentDetail.paymentDetailId,
					);

					return {
						paymentDetailId,
						isValid: updatedPaymentDetail
							? action.payload.isValid
							: Boolean(existingPaymentDetail?.isValid),
					};
				});
			} else {
				isValidPaymentDetailsMap = [
					{
						paymentDetailId: action.payload.paymentDetailId,
						isValid: Boolean(action.payload.isValid),
						errors: action.payload.errors,
					},
				];
			}

			const isValidPaymentDetails = Boolean(
				isValidPaymentDetailsMap.every(paymentDetail => paymentDetail.isValid),
			);

			return {
				...state,
				isValidPaymentDetails,
				isValidPaymentDetailsMap,
			};
		}

		case 'SET_ACTIVE_ORDER': {
			const { payload: order } = action;

			return {
				...state,
				activeOrder: order,
			};
		}

		case 'UPDATE_PARTICIPANT': {
			const { payload } = action;

			if (!state.cartItems?.length) {
				return state;
			}
			const updatedCartItems = state.cartItems.map(cartItem => {
				if (extractId(cartItem.orderedCollection.value) !== payload.courseversionId) {
					return cartItem;
				}

				return {
					...cartItem,
					participants: cartItem.participants?.map(participant => {
						if (participant.id !== payload.participant.id!) {
							return participant;
						}

						return payload.participant;
					}),
				};
			});

			return {
				...state,
				cartItems: updatedCartItems,
			};
		}

		case 'ADD_ITEM': {
			// if the item is already in the cart, increase the quantity
			const { payload: incomingItem } = action;

			if (!incomingItem) {
				return state;
			}

			const indexInCart = findCartItemIndex(state, incomingItem);

			const cartItemsWithAddedItem = [...(state?.cartItems || [])];

			if (indexInCart === -1) {
				cartItemsWithAddedItem.push(incomingItem);
			}

			if (typeof indexInCart === 'number' && indexInCart > -1) {
				const incomingItemQuantity = incomingItem.quantity || 0;
				const existingItemQuantity = cartItemsWithAddedItem[indexInCart].quantity || 0;

				// replace existing quantity with incoming quantity
				cartItemsWithAddedItem[indexInCart] = {
					...cartItemsWithAddedItem[indexInCart],
					...incomingItem,
					quantity:
						(incomingItem.quantity || 0) > 0
							? incomingItemQuantity
							: existingItemQuantity,
				};
			}

			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: cartItemsWithAddedItem,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
			});

			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...state,
				cartItems,
			};
		}

		case 'DELETE_ITEM': {
			const { payload: incomingItem } = action;
			const withDeletedItem = { ...state };

			const indexInCart = findCartItemIndex(state, incomingItem);

			if (typeof indexInCart === 'number' && withDeletedItem.cartItems && indexInCart > -1) {
				withDeletedItem.cartItems.splice(indexInCart, 1);
			}

			// clear cart
			if (!withDeletedItem.cartItems?.length) {
				return {
					...state,
					discountCode: null,
					discountCodeText: null,
					cartItems: [],
					activeOrder: null,
					paymentDetails: [],
				};
			}

			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: withDeletedItem.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
			});
			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...state,
				cartItems,
			};
		}

		case 'CLEAR_CART': {
			return {
				...cartDefaultValues,
				hasInitialSync: true,
			};
		}

		case 'SET_DISCOUNT_CODE_TEXT': {
			return {
				...state,
				discountCodeText: action.payload,
			};
		}

		case 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT': {
			// recalculate because single instalment payment gets discounts and vice versa
			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: state.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: action.payload,
			});

			return {
				...state,
				cartItems: cartItemsWithPrices,
				chosenInstalmentPaymentsCount: action.payload,
			};
		}

		case 'SET_PAYER_TYPE': {
			let paymentType = state.paymentType;

			// force multiple payers to invoice
			if (action.payload === 'multiple-payers') {
				paymentType = 'invoice';
			}

			// force tootukassa to "tootukassa" payment type
			if (action.payload === 'tootukassa') {
				paymentType = 'tootukassa';
			}

			return {
				...state,
				payerType: action.payload,
				paymentDetails: [],
				paymentType,
			};
		}

		case 'SET_HAS_CHOSEN_INSTALMENT_PAYMENTS': {
			return {
				...state,
				hasChosenInstalmentPayments: action.payload,
			};
		}

		case 'SET_EVERYPAY_PAYMENT_METHOD_SOURCE': {
			return {
				...state,
				everyPayPaymentMethodSource: action.payload,
			};
		}

		case 'SET_PAYMENT_TYPE': {
			return {
				...state,
				paymentType: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

function findCartItemIndex(cart: CartStateType, incomingItem: CartItem) {
	const incomingItemType = incomingItem?.orderedCollection?.relationTo;

	if (!incomingItem) {
		return -1;
	}

	return cart?.cartItems?.findIndex(({ orderedCollection }) => {
		if (!orderedCollection) {
			return false;
		}

		const incomingItemId = extractId(incomingItem.orderedCollection.value);
		return (
			extractId(orderedCollection.value) === incomingItemId &&
			incomingItemType === orderedCollection.relationTo
		);
	});
}
