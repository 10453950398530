import { ReactNode } from 'react';
import Attention from '../_icons/Attention';
import ChevronCircleTick from '../_icons/Chevron-circle-tick';
import { Info } from 'lucide-react';

type IconType = 'attention' | 'check' | 'info';
type Type = 'warning' | 'info';
function getIcon(iconType: IconType, type: Type) {
	const color = type === 'warning' ? 'text-error' : 'text-ebs-blue-500';
	if (iconType === 'attention') {
		return <Attention color="white" />;
	}

	if (iconType === 'check') {
		return <ChevronCircleTick className="" />;
	}

	if (iconType === 'info') {
		return <Info className={` ${color}`} />;
	}
}

export const AttentionBox: React.FC<{
	text: string;
	children?: ReactNode;
	className?: string;
	showIcon?: boolean;
	iconType?: IconType;
	type?: Type;
}> = props => {
	const {
		text,
		children,
		className = '',
		showIcon,
		iconType = 'attention',
		type = 'warning',
	} = props;

	const bgColor = type === 'warning' ? 'bg-ebs-golden-100' : 'bg-ebs-blue-100';

	return (
		<div className="">
			<div className={`${bgColor} flex w-full items-center p-6 ${className}`}>
				{showIcon && (
					<div className="mr-4 size-6 self-start">{getIcon(iconType, type)}</div>
				)}
				<p className="text-ebs-blue-500 self-start">
					{text}
					{children || ''}
				</p>
			</div>
		</div>
	);
};
