'use client';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Mode, useForm } from 'react-hook-form';
import { Button } from '../ButtonOld';
import ChevronRight from '../../_icons/Chevron-right';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@shadcn/components/ui/form';
import { FormInput } from '@shadcn/components/ui/input';

import { useTranslations, useLocale } from 'next-intl';
import { useAuth } from '../../_providers/Auth';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { useCart } from '../../_providers/Cart';
import { PaymentDetail } from '../../_providers/Cart/reducer';
import { Link } from '@/navigation';
import { OrderCreationErrorType } from '../../_utilities/typescript';
import _ from 'lodash';
import {
	MULTIPLE_PAYER_FIRST_INTERNAL_ID,
	MULTIPLE_PAYER_SECOND_INTERNAL_ID,
} from '@/app/(frontend)/_components/Forms/multiple-payers';
import { ListItem, List, Paragraph, Title } from '@/app/(frontend)/_components/Typography';
import { formatPrice } from '@/app/(frontend)/_utilities/format-price';
import { AttentionBox } from '@/app/(frontend)/_components/attention-box';
import { RadioToggle } from '@/app/(frontend)/_components/radio-toggle';

export const OrderPayerForm: React.FC<{
	onSubmit?: (data: PaymentDetail) => unknown;
	payerType: PaymentDetail['payerType'];
	mode?: Mode;
	paymentDetailId: string;
	onChange?: (data: PaymentDetail) => unknown;
	error?: string;
	errors?: OrderCreationErrorType[];
	isSubmitInProgress: boolean;
}> = props => {
	const {
		onSubmit,
		payerType,
		mode = 'onSubmit',
		onChange,
		paymentDetailId,
		isSubmitInProgress,
		error,
		errors,
	} = props;
	const t = useTranslations('Checkout');
	const locale = useLocale();
	const { user } = useAuth();
	const { cart, shouldTriggerPaymentDetailFormValidation, setIsValidPaymentDetails, cartTotal } =
		useCart();
	const existingPaymentDetail =
		cart?.activeOrder?.paymentDetails?.find(
			paymentDetail => paymentDetail.payerType === payerType,
		) || cart?.paymentDetails?.find(paymentDetail => paymentDetail.payerType === payerType);
	const isMultiplePayer =
		paymentDetailId === MULTIPLE_PAYER_FIRST_INTERNAL_ID ||
		paymentDetailId === MULTIPLE_PAYER_SECOND_INTERNAL_ID;
	const isMultiplePayerFirst = paymentDetailId === MULTIPLE_PAYER_FIRST_INTERNAL_ID;
	const isMultiplePayerSecond = paymentDetailId === MULTIPLE_PAYER_SECOND_INTERNAL_ID;

	const emailValidation = z.coerce
		.string()
		.min(1, { message: ' ' })
		.email({ message: t('invalid-email') });
	const tootukassaSchema = z.object({
		payerType: z.enum(['personal', 'company', 'tootukassa']),
		email: emailValidation,
		firstName: z.string().min(1, { message: ' ' }),
		lastName: z.string().min(1, { message: ' ' }),
		personalIdNumber: z.string().min(1, ' ').optional().or(z.literal('')),
		agreementWithTootukassa: z.boolean().optional(),
	});

	let personalSchema = z.object({
		payerType: z.enum(['personal', 'company', 'tootukassa']),
		email: emailValidation,
		firstName: z.string().min(1, { message: ' ' }),
		lastName: z.string().min(1, { message: ' ' }),
		personalIdNumber: z.string().min(1, ' ').optional().or(z.literal('')),
		instalmentShare: z.number().optional(),
	});
	let companySchema = z.object({
		firstName: z.string().min(1, { message: ' ' }),
		lastName: z.string().min(1, { message: ' ' }),
		payerType: z.enum(['personal', 'company', 'tootukassa']),
		email: emailValidation,
		companyName: z.string().min(1, { message: ' ' }),
		companyRegNr: z.string().min(1, { message: ' ' }),
		companyAddress: z.string().min(1, { message: ' ' }),
		postalcode: z.string().min(1, { message: ' ' }),
		city: z.string().min(1, { message: ' ' }),
		kmkr: z.string().optional(),
		instalmentShare: z.number().optional(),
	});

	if (isMultiplePayer && isMultiplePayerFirst) {
		// @ts-expect-error doesnt like instalmentshare .optional()
		personalSchema = personalSchema.extend({
			instalmentShare: z.coerce
				.number()
				.min(1, { message: ' ' })
				.max(cartTotal.raw - 1, { message: ' ' }),
		});
		// @ts-expect-error doesnt like instalmentshare .optional()
		companySchema = companySchema.extend({
			instalmentShare: z.coerce
				.number()
				.min(1, { message: ' ' })
				.max(cartTotal.raw - 1, { message: ' ' }),
		});
	}
	const formSchemaMap = {
		tootukassa: tootukassaSchema,
		company: companySchema,
		personal: personalSchema,
	};
	const defaultValues = {
		email: existingPaymentDetail?.email || user?.email || '',
		firstName: existingPaymentDetail?.firstName || user?.firstName || '',
		lastName: existingPaymentDetail?.lastName || user?.lastName || '',
		personalIdNumber: existingPaymentDetail?.personalIdNumber || user?.personalIdNumber || '',
		companyName: existingPaymentDetail?.companyName || '',
		companyRegNr: existingPaymentDetail?.companyRegNr || '',
		companyAddress: existingPaymentDetail?.companyAddress || '',
		postalcode: existingPaymentDetail?.postalcode || '',
		city: existingPaymentDetail?.city || '',
		kmkr: existingPaymentDetail?.kmkr || '',
		payerType,
		instalmentShare: Number.isFinite(existingPaymentDetail?.instalmentShare)
			? existingPaymentDetail?.instalmentShare
			: 0,
	};

	const form = useForm<z.infer<(typeof formSchemaMap)[typeof payerType]>>({
		resolver: zodResolver(formSchemaMap[payerType]),
		defaultValues,
		mode,
		shouldUnregister: true,
		disabled: isSubmitInProgress,
	});

	const [secondPayerAmountPercent, setSecondPayerAmountPercent] = useState<number>(0);

	useEffect(() => {
		if (shouldTriggerPaymentDetailFormValidation) {
			form.trigger(undefined, { shouldFocus: true });
		}
	}, [shouldTriggerPaymentDetailFormValidation, form]);

	useEffect(() => {
		setIsValidPaymentDetails(form.formState.isValid, paymentDetailId, form.formState.errors);
	}, [form.formState.isValid, form.formState.errors]);

	// update multiple payer second payer instalmentShare here as its calculated from first payers share
	useEffect(() => {
		if (isMultiplePayer && isMultiplePayerSecond) {
			const firstPayerInstalmentShare = cart.paymentDetails?.find(
				paymentDetail => paymentDetail.id === MULTIPLE_PAYER_FIRST_INTERNAL_ID,
			)?.instalmentShare;
			if (firstPayerInstalmentShare && firstPayerInstalmentShare > 0) {
				form.setValue('instalmentShare', cartTotal.raw - firstPayerInstalmentShare);
				const firstPayerPercentage = _.round(
					(firstPayerInstalmentShare / cartTotal.raw) * 100,
				);
				setSecondPayerAmountPercent(_.round(100 - firstPayerPercentage));
			}
		}
	}, [cart.paymentDetails]);

	useEffect(() => {
		if (mode === 'onChange' && onChange) {
			const subscription = form.watch((value, { name, type }) => {
				// Skip if the change is for payerType since we handle it separately
				if (name === 'payerType') return;

				if (typeof value.payerType !== 'undefined') {
					const values = form.getValues();
					onChange(values);
				}
			});
			return () => subscription?.unsubscribe?.();
		}
	}, [mode]);

	const DebugErrorMessages = () => {
		if (
			(process.env.NEXT_PUBLIC_TARGET_ENV === 'development' ||
				process.env.NEXT_PUBLIC_TARGET_ENV === 'test') &&
			form.formState.errors &&
			Object.keys(form.formState.errors).length > 0
		) {
			return (
				<Fragment>
					<List ordered>
						vormi errorid, (laivis ei näita):{' '}
						{Object.keys(form.formState.errors).map(key => (
							<ListItem key={key}>
								{/* @ts-expect-error not important */}
								{key}: {form.formState.errors[key]?.message}
							</ListItem>
						))}
					</List>
				</Fragment>
			);
		}

		return null;
	};

	const payerTypeValue = form.watch('payerType');
	if (payerTypeValue === 'company') {
		return (
			<Form {...form}>
				<form
					key={`${payerType}-${paymentDetailId ? paymentDetailId : ''}`}
					onSubmit={onSubmit ? form.handleSubmit(onSubmit) : () => null}
					className="space-y-2"
				>
					{!isMultiplePayer && (
						<FormField
							control={form.control}
							name="payerType"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<FormInput
											{...field}
											key={`${payerType}-payerType`}
											type="hidden"
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
					)}

					{isMultiplePayer && isMultiplePayerFirst && (
						<FormField
							control={form.control}
							name="instalmentShare"
							render={({ field }) => (
								<FormItem className="flex flex-row items-center">
									<Title bold>{t('first-payer')}</Title> &nbsp;&nbsp;
									<Paragraph>{t('pays')}</Paragraph> &nbsp;&nbsp;
									<FormControl>
										<div className="relative inline-block">
											<FormInput
												type="number"
												className="text-ebs-blue-500 m-0 w-24 max-w-28 appearance-none rounded-xs border py-2 pr-6 pl-4 [-moz-appearance:_textfield]"
												min={0}
												max={cartTotal.raw}
												step="any"
												{...field}
												{...form.register('instalmentShare', {
													valueAsNumber: true,
												})}
											/>
											<Paragraph className="absolute top-2 right-2">
												€
											</Paragraph>
										</div>
									</FormControl>{' '}
									&nbsp;&nbsp;
									<Paragraph>
										{t('multiple-payers-percentage-wholesum', {
											percentage: Number.isFinite(
												form.getValues('instalmentShare'),
											)
												? _.round(
														((form.getValues('instalmentShare') || 0) /
															cartTotal.raw) *
															100,
													)
												: 0,
											sum: formatPrice(cartTotal.raw, locale),
										})}
									</Paragraph>
								</FormItem>
							)}
						/>
					)}

					{isMultiplePayer && isMultiplePayerSecond && (
						<div className="flex flex-row items-center">
							<Title bold>{t('second-payer')}</Title> &nbsp;&nbsp;
							<Paragraph>
								{t('pays')}{' '}
								{t('multiple-payers-sum-percentage', {
									percentage: secondPayerAmountPercent,
									sum: formatPrice(
										form.getValues('instalmentShare') || 0,
										locale,
									),
								})}
							</Paragraph>
						</div>

						// <FormField
						// 	control={form.control}
						// 	name="instalmentShare"
						// 	render={({ field }) => (
						// 		<FormItem className="flex flex-row items-center">
						// 			<Title bold>{t('second-payer')}</Title> &nbsp;&nbsp;
						// 			<Paragraph>
						// 				{t('pays')}{' '}
						// 				{t('multiple-payers-sum-percentage', {
						// 					percentage: secondPayerAmountPercent,
						// 					sum: formatPrice(
						// 						form.getValues('instalmentShare'),
						// 						locale,
						// 					),
						// 				})}
						// 			</Paragraph>
						// 			<FormControl>
						// 				<FormInput
						// 					type="hidden"
						// 					className="text-ebs-blue-500 m-0 w-24 max-w-28 appearance-none rounded-xs border py-2 pr-6 pl-4 [-moz-appearance:_textfield]"
						// 					min={0}
						// 					max={cartTotal.raw}
						// 					step="any"
						// 					{...field}
						// 				/>
						// 			</FormControl>
						// 		</FormItem>
						// 	)}
						// />
					)}

					{isMultiplePayer && (
						<FormField
							control={form.control}
							name="payerType"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<RadioToggle
											options={[
												{ label: t('personal'), value: 'personal' },
												{ label: t('company'), value: 'company' },
											]}
											onChange={value => {
												if (onChange) {
													onChange({
														...form.getValues(),
														payerType: value,
													});
												}
											}}
											value={field.value}
										/>
									</FormControl>
								</FormItem>
							)}
						/>
					)}

					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel aria-required>{t('email')}</FormLabel>
								<FormControl>
									<FormInput
										{...field}
										key={`${payerType}-email`}
										placeholder={t('email')}
										type="email"
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="firstName"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('firstName')}</FormLabel>
								<FormControl>
									<FormInput
										placeholder={t('firstName')}
										type="text"
										key={`${payerType}-firstName`}
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="lastName"
						render={({ field }) => (
							<FormItem className="mb-1" key={`${payerType}-lastname-FormItem`}>
								<FormLabel aria-required>{t('lastName')}</FormLabel>
								<FormControl key={`${payerType}-lastname-formcontrol`}>
									<FormInput
										placeholder={t('lastName')}
										key={`${payerType}-lastName`}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="companyName"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('companyName')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-companyName`}
										placeholder={t('companyName')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="companyRegNr"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('companyRegNr')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-companyRegNr`}
										placeholder={t('companyRegNr')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="companyAddress"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('companyAddress')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-companyAddress`}
										placeholder={t('companyAddress')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="postalcode"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('postalcode')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-postalcode`}
										placeholder={t('postalcode')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="city"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('city')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-city`}
										placeholder={t('city')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="kmkr"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel>{t('kmkr')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-kmkr`}
										placeholder={t('kmkr')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>

					{mode === 'onSubmit' && (
						<Button
							className="w-full lg:w-fit"
							type="submit"
							disabled={isSubmitInProgress}
							loading={isSubmitInProgress}
						>
							{t('payment')}
							<ChevronRight />
						</Button>
					)}
				</form>
				<DebugErrorMessages />
			</Form>
		);
	} else if (payerTypeValue === 'personal') {
		return (
			<Form {...form}>
				<form
					key={`${payerType}-${paymentDetailId ? paymentDetailId : ''}`}
					onSubmit={onSubmit ? form.handleSubmit(onSubmit) : () => null}
					className="space-y-2"
				>
					{!isMultiplePayer && (
						<FormField
							control={form.control}
							name="payerType"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<FormInput
											type="hidden"
											key={`${payerType}-payerType`}
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
					)}
					{isMultiplePayer && isMultiplePayerFirst && (
						<FormField
							control={form.control}
							name="instalmentShare"
							render={({ field }) => (
								<FormItem className="flex flex-row items-center">
									<Title bold>{t('first-payer')}</Title> &nbsp;&nbsp;
									<Paragraph>{t('pays')}</Paragraph> &nbsp;&nbsp;
									<FormControl>
										<div className="relative inline-block">
											<FormInput
												type="number"
												className="text-ebs-blue-500 m-0 w-24 max-w-28 appearance-none rounded-xs border py-2 pr-6 pl-4 [-moz-appearance:_textfield]"
												min={0}
												max={cartTotal.raw}
												step="any"
												{...field}
												{...form.register('instalmentShare', {
													valueAsNumber: true,
												})}
											/>
											<Paragraph className="absolute top-2 right-2">
												€
											</Paragraph>
										</div>
									</FormControl>{' '}
									&nbsp;&nbsp;
									<Paragraph>
										{t('multiple-payers-percentage-wholesum', {
											percentage: Number.isFinite(
												form.getValues('instalmentShare'),
											)
												? _.round(
														((form.getValues('instalmentShare') || 0) /
															cartTotal.raw) *
															100,
													)
												: 0,
											sum: formatPrice(cartTotal.raw, locale),
										})}
									</Paragraph>
								</FormItem>
							)}
						/>
					)}
					{isMultiplePayer && isMultiplePayerSecond && (
						<FormField
							control={form.control}
							name="instalmentShare"
							render={({ field }) => (
								<FormItem className="flex flex-row items-center">
									<Title bold>{t('second-payer')}</Title> &nbsp;&nbsp;
									<Paragraph>
										{t('pays')}{' '}
										{t('multiple-payers-sum-percentage', {
											percentage: secondPayerAmountPercent,
											sum: formatPrice(
												form.getValues('instalmentShare') || 0,
												locale,
											),
										})}
									</Paragraph>
									<FormControl>
										<div className="relative inline-block">
											<FormInput
												type="hidden"
												className="text-ebs-blue-500 m-0 w-24 max-w-28 appearance-none rounded-xs border py-2 pr-6 pl-4 [-moz-appearance:_textfield]"
												min={0}
												max={cartTotal.raw}
												step="any"
												{...field}
												{...form.register('instalmentShare', {
													valueAsNumber: true,
												})}
											/>
										</div>
									</FormControl>
								</FormItem>
							)}
						/>
					)}
					{isMultiplePayer && (
						<FormField
							control={form.control}
							name="payerType"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<RadioToggle
											options={[
												{ label: t('personal'), value: 'personal' },
												{ label: t('company'), value: 'company' },
											]}
											onChange={value => {
												if (onChange) {
													onChange({
														...form.getValues(),
														payerType: value,
													});
												}
											}}
											value={field.value}
										/>
									</FormControl>
								</FormItem>
							)}
						/>
					)}
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel aria-required>{t('email')}</FormLabel>
								<FormControl>
									<FormInput
										{...field}
										key={`${payerType}-email`}
										placeholder={t('email')}
										type="email"
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="firstName"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel aria-required>{t('firstName')}</FormLabel>
								<FormControl>
									<FormInput
										placeholder={t('firstName')}
										type="text"
										key={`${payerType}-firstName`}
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="lastName"
						render={({ field }) => (
							<FormItem className="mb-1" key={`${payerType}-lastname-FormItem`}>
								<FormLabel aria-required>{t('lastName')}</FormLabel>
								<FormControl key={`${payerType}-lastname-formcontrol`}>
									<FormInput
										placeholder={t('lastName')}
										key={`${payerType}-lastName`}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="personalIdNumber"
						render={({ field }) => (
							<FormItem className="mb-1">
								<FormLabel>{t('personalIdNumber')}</FormLabel>
								<FormControl>
									<FormInput
										key={`${payerType}-personalIdNumber`}
										// comment={
										// 	t.rich('personalIdNumber-comment', {
										// 		link: text => (
										// 			<a
										// 				className="link-styles"
										// 				target="_blanks"
										// 				href="https://www.riigiteataja.ee/akt/102052024014#para56b2"
										// 			>
										// 				{text}
										// 			</a>
										// 		),
										// 	}) as string | React.ReactElement
										// }
										placeholder={t('personalIdNumber')}
										type="text"
										{...field}
									/>
								</FormControl>

								<FormMessage>&nbsp;</FormMessage>
							</FormItem>
						)}
					/>

					{mode === 'onSubmit' && (
						<Button
							className="w-full lg:w-fit"
							type="submit"
							disabled={isSubmitInProgress}
							loading={isSubmitInProgress}
						>
							{t('payment')}
							<ChevronRight />
						</Button>
					)}
				</form>
				<DebugErrorMessages />
			</Form>
		);
	} else if (payerTypeValue === 'tootukassa') {
		return (
			<div>
				<AttentionBox
					showIcon
					iconType="info"
					type="info"
					text={t('tootukassaInfoMessage')}
				/>
				{/* <p className="bg-neutral-black text-muted-navy my-10 max-w-2xl rounded-xl p-4 lg:p-10">
					{t('tootukassaInfoMessage')}
				</p> */}

				<Form {...form}>
					<form
						key={`${payerType}${paymentDetailId ? '-' + paymentDetailId : ''}`}
						onSubmit={onSubmit ? form.handleSubmit(onSubmit) : () => null}
						className="space-y-2"
					>
						<FormField
							control={form.control}
							name="payerType"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<FormInput
											key={`${payerType}-payerType`}
											type="hidden"
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="firstName"
							render={({ field }) => (
								<FormItem className="mb-1">
									<FormLabel aria-required>{t('firstName')}</FormLabel>
									<FormControl>
										<FormInput
											key={`${payerType}-firstName`}
											placeholder={t('firstName')}
											type="text"
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="lastName"
							render={({ field }) => (
								<FormItem className="mb-1">
									<FormLabel aria-required>{t('lastName')}</FormLabel>
									<FormControl>
										<FormInput
											key={`${payerType}-lastName`}
											placeholder={t('lastName')}
											type="text"
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormLabel aria-required>{t('email')}</FormLabel>
									<FormControl>
										<FormInput
											key={`${payerType}-email`}
											placeholder={t('email')}
											type="email"
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="personalIdNumber"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('personalIdNumber')}</FormLabel>
									<FormControl>
										<FormInput
											key={`${payerType}-personalIdNumber`}
											placeholder={t('personalIdNumber')}
											type="text"
											{...field}
										/>
									</FormControl>

									<FormMessage>&nbsp;</FormMessage>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="agreementWithTootukassa"
							render={({ field }) => (
								<FormItem>
									<FormLabel></FormLabel>
									<div className="flex w-full flex-row items-center">
										<FormControl>
											<Paragraph className="flex flex-row items-center space-x-4">
												<Checkbox
													key={`${payerType}-agreementWithTootukassa`}
													checked={field.value}
													onCheckedChange={field.onChange}
												/>
												{t('agreementWithTootukassa')}
											</Paragraph>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>

						{mode === 'onSubmit' && (
							<Button
								className="w-full lg:w-fit"
								type="submit"
								disabled={isSubmitInProgress}
								loading={isSubmitInProgress}
							>
								{payerType === 'tootukassa' ? t('submit') : t('payment')}

								<ChevronRight />
							</Button>
						)}
						<DebugErrorMessages />
					</form>
				</Form>
			</div>
		);
	}

	return null;
};
