import { calculatePrice } from './price';
import { Courseversion, OrderCartItems, DiscountCode, Order } from '../payload-types';
import { Flatten, extractId } from './typescript';
import { getDiscountCodePriceReduction } from './discount-code';
import { VAT_MULTIPLIER } from './config';
import { PublicDiscountCode } from '@/shared/typescript';

type OrderCartItem = Flatten<OrderCartItems>;
type GenerateSingleCartOrderItemPriceParams = {
	item: NonNullable<OrderCartItem>;
	// items must contain the courseversions, this is for depth if FE wants to use it?
	courseversion: Courseversion;
	quantity: number;
	// business logic: discount code price reduction can only be done once
	canApplyDiscountCode?: boolean;
	discountCode?: DiscountCode | PublicDiscountCode | string | null;
	shouldFlattenOrderedCollection?: boolean;
	chosenInstalmentPaymentsCount: Order['chosenInstalmentPaymentsCount'];
};
export function generateSingleCartOrderItemPrice({
	item,
	courseversion,
	discountCode,
	//canApplyDiscountCode,
	shouldFlattenOrderedCollection,
	quantity,
	chosenInstalmentPaymentsCount,
}: GenerateSingleCartOrderItemPriceParams): NonNullable<OrderCartItem> {
	let discountedPriceWithoutVAT = courseversion.discountedPriceWithoutVAT;
	let discountedVAT = courseversion.discountedVAT;
	let discountedPrice = courseversion.discountedPrice;

	const discountCodePriceReduction = getDiscountCodePriceReduction({
		discountCode,
		courseversion,
		quantity,
	});
	const discountCodeApplied = discountCodePriceReduction > 0;

	let discountedPriceReduction = 0;

	if (discountedPrice && discountedPrice > 0) {
		if (discountCodeApplied) {
			discountedPriceReduction = 0;
			discountedPrice = null;
			discountedPriceWithoutVAT = null;
			discountedVAT = null;
		} else {
			discountedPriceReduction = calculatePrice(
				(courseversion.price - (discountedPrice || 0)) * quantity,
			);
		}
	}

	let discountedInstalmentPriceReduction = 0;
	let isInstalmentPaymentsPriceReductionApplied = false;
	const hasChosenSinglePayment = chosenInstalmentPaymentsCount === 1;

	// apply instalmentSinglePaymentPriceReductionPercentage to cartitem price
	if (
		courseversion.instalmentSinglePaymentPriceReductionPercentage &&
		courseversion.isInstalmentPaymentsEnabled &&
		hasChosenSinglePayment
	) {
		const instalmentDiscountedPrice = calculatePrice(
			courseversion.price -
				courseversion.price *
					(courseversion.instalmentSinglePaymentPriceReductionPercentage / 100),
		);
		const instalmentSingleItemPriceReduction = calculatePrice(
			courseversion.price - instalmentDiscountedPrice,
		);

		discountedPrice = calculatePrice(
			(discountedPrice || courseversion.price) - instalmentSingleItemPriceReduction,
		);

		discountedPriceWithoutVAT =
			courseversion.VAT > 0
				? calculatePrice(discountedPrice / VAT_MULTIPLIER)
				: discountedPrice;

		discountedVAT =
			courseversion.VAT > 0 ? calculatePrice(discountedPrice - discountedPriceWithoutVAT) : 0;

		isInstalmentPaymentsPriceReductionApplied = true;
		discountedInstalmentPriceReduction = calculatePrice(
			quantity * instalmentSingleItemPriceReduction,
		);
	}

	const discountedPriceReductionTotal = calculatePrice(
		discountedPriceReduction + discountedInstalmentPriceReduction,
	);

	return {
		...item,
		orderedCollection: {
			...item.orderedCollection,
			value: shouldFlattenOrderedCollection
				? extractId(item.orderedCollection.value)
				: item.orderedCollection.value,
		},
		priceWithoutVAT: courseversion.enteredPrice,
		VAT: courseversion.VAT,
		price: courseversion.price,
		discountedPriceWithoutVAT,
		discountedVAT,
		discountedPrice,
		discountCodeApplied,
		discountedInstalmentPriceReduction,
		discountCodePriceReduction,
		discountedPriceReduction,
		discountedPriceReductionTotal,
		isInstalmentPaymentsPriceReductionApplied,
	};
}

type GeneratePricesParams = {
	items?: OrderCartItems;
	discountCode?: DiscountCode | PublicDiscountCode | null | string;
	shouldFlattenOrderedCollection?: boolean;
	chosenInstalmentPaymentsCount: Order['chosenInstalmentPaymentsCount'];
};

export function generateCartOrderItemPrices({
	items,
	discountCode,
	shouldFlattenOrderedCollection,
	chosenInstalmentPaymentsCount,
}: GeneratePricesParams): OrderCartItems {
	if (!items?.length) {
		return [];
	}

	if (typeof discountCode === 'string') {
		console.warn(
			`generateSingleCartOrderItemPrice: discountCode is of type string, id: ${discountCode}`,
		);
	}

	let updatedItems = [...items];
	let discountCodeAppliedItemsCount = 0;

	for (const [index, item] of items.entries()) {
		const relatedCourseVersion = item.orderedCollection.value;

		if (typeof relatedCourseVersion === 'string') {
			throw new Error('Bad depth for ordered item courseversion');
		}

		if (!relatedCourseVersion || !relatedCourseVersion.price) {
			throw new Error(`Courseversion with id ${relatedCourseVersion.id} cannot be ordered`);
		}

		const relatedCourse = relatedCourseVersion.course;
		if (typeof relatedCourse === 'string') {
			throw new Error('Depth: cannot get course from courseversion');
		}

		const itemWithPrices = generateSingleCartOrderItemPrice({
			item,
			courseversion: relatedCourseVersion,
			discountCode: discountCode,
			shouldFlattenOrderedCollection,
			quantity: item.quantity,
			chosenInstalmentPaymentsCount,
		});

		if (itemWithPrices.discountCodeApplied) {
			discountCodeAppliedItemsCount++;
		}

		updatedItems[index] = itemWithPrices;
	}

	if (!discountCode || typeof discountCode === 'string') {
		return updatedItems;
	}
	// separate logic for discountCode price calculation
	// discount code should be removed from total price
	// but discount code might have exclusions/inclusions
	// and we want to show every item discount on a separate row
	// 1. we separate the inclusions/exclusions
	// 2. give every ordered item share of discountcode reduction
	// 3. handle remainder by adding it to the first item if there are multiples

	let shouldModifyRemainder =
		discountCode.flatPrice &&
		discountCode.discountType === 'flatValue' &&
		discountCodeAppliedItemsCount > 1;
	updatedItems = updatedItems.map((item, i) => {
		if (!item.discountCodeApplied) {
			return item;
		}
		let discountCodePriceReduction = item.discountCodePriceReduction || 0;

		if (discountCode.flatPrice && discountCode.discountType === 'flatValue') {
			discountCodePriceReduction = calculatePrice(
				discountCodePriceReduction / discountCodeAppliedItemsCount,
			);

			if (shouldModifyRemainder) {
				discountCodePriceReduction =
					discountCode.flatPrice -
					discountCodePriceReduction * (discountCodeAppliedItemsCount - 1);
				shouldModifyRemainder = false;
			}
		}

		return {
			...item,
			discountCodePriceReduction,
			discountedPriceReductionTotal:
				(item.discountedPriceReductionTotal || 0) + discountCodePriceReduction,
		};
	});

	return updatedItems;
}
