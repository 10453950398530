'use client';

import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';

import { z } from 'zod';

import type {
	OrderCartItems,
	Order,
	Courseversion,
	InstalmentPayments,
	DiscountCode,
} from '@/payload/payload-types';
import type {
	CartItem,
	PaymentDetails,
	CartStateType,
	OrderParticipant,
	FlattenedCartStateType,
	PaymentDetail,
	PayerType,
} from './reducer';

import { useAuth } from '../Auth';
import { cartDefaultValues, cartReducer } from './reducer';
import { useLocale, useTranslations } from 'next-intl';
import { formatPrice } from '../../_utilities/format-price';
import { isTruthy } from '../../_utilities/is-truthy';
import { extractId } from '@/shared/extract-id';
import { generateCartOrderTotalPrices } from '@/payload/utilities/generate-cart-order-total-price';
import { toast } from '@shadcn/components/ui/use-toast';
import { Link } from '@/navigation';
import { Button } from '../../_components/ButtonOld';
import { generateInstalmentPaymentsCalendar } from '@/payload/utilities/generate-instalment-payments-calendar';
import { logToPayload } from '../../_utilities/log-to-payload';
import { PaymentMethod } from '@/payload/types/every-pay';
import { PublicDiscountCode } from '@/shared/typescript';
import { useDebounceCallback } from 'usehooks-ts';
import { FieldErrors } from 'react-hook-form';

export type InstalmentPaymentsWithShares = {
	instalmentShareCount: number;
	instalmentPaymentsCalendar?: InstalmentPayments;
};
type TotalType = { formatted: string; raw: number };
export type CartContext = {
	addItemToCart: (item: CartItem) => void;
	setPaymentDetails: (
		paymentDetails: Order['paymentDetails'],
		isMultiplePayers?: boolean,
	) => void;
	cart: CartStateType;
	cartIsEmpty: boolean | undefined;
	cartTotal: TotalType;
	cartTotalVat: TotalType;
	cartTotalDiscountCodeReduction: TotalType;
	cartHasVat: boolean;
	clearCart: () => void;
	deleteItemFromCart: (cartItem: CartItem) => void;
	hasInitializedCart: boolean;
	isItemInCart: (cartItem: CartItem) => boolean;
	setActiveOrder: (order?: Order) => void;
	setDiscountCodeText: (discountCodeText?: string) => void;
	isCartSyncInProgress: boolean;
	discountCodeError?: string | null;
	isDiscountCodeLoading: boolean;
	clearDiscountCode: () => void;
	canShowInstalmentPayments: boolean;
	instalmentPaymentsCalendars?: InstalmentPaymentsWithShares[];
	setChosenInstalmentPaymentsCount: (count: Order['chosenInstalmentPaymentsCount']) => void;
	instalmentSinglePaymentPriceReductionPercentage?: Courseversion['instalmentSinglePaymentPriceReductionPercentage'];
	updateParticipant: (participant: OrderParticipant, courseversionId: string) => void;
	shouldTriggerParticipantFormValidation: boolean;
	shouldTriggerPaymentDetailFormValidation: boolean;
	setShouldTriggerParticipantFormValidation: (shouldTrigger: boolean) => void;
	setShouldTriggerPaymentDetailFormValidation: (shouldTrigger: boolean) => void;
	setIsValidPaymentDetails: (
		isValid: boolean,
		paymentDetailId: string,
		errors?: FieldErrors,
	) => void;
	participantFormState?: Record<string, { isValid: boolean }>;
	updateParticipantFormState: (participantId: string, isValid: boolean) => void;
	setPayerType: (payerType: PayerType) => void;
	setHasChosenInstalmentPayments: (hasChosenInstalmentPayments: boolean) => void;
	setPaymentType: (paymentType: CartStateType['paymentType']) => void;
	setEveryPayPaymentSource: (
		paymentMethodSource: CartStateType['everyPayPaymentMethodSource'],
	) => void;
	setIsPaymentRequestInProgress: (paymentRequestInProgress: boolean) => void;
	isPaymentRequestInProgress: boolean;
};

const Context = createContext({} as CartContext);

export const cartItemSchema = z.object({
	orderedCollection: z.object({
		relationTo: z.union([z.literal('courseversions'), z.literal('products')]),
		value: z.string(),
	}),
	selfParticipant: z.boolean(),
	quantity: z.number().min(1),
});
export const useCart = () => useContext(Context);

const arrayHasItems = (array: unknown[] | undefined | null): boolean =>
	Array.isArray(array) && array.length > 0;

const flattenCart = (cart: CartStateType): FlattenedCartStateType => {
	const cartItems = cart?.cartItems
		?.map(cartItem => {
			if (!cartItem?.orderedCollection || typeof cartItem?.orderedCollection !== 'object') {
				return null;
			}

			if ((cartItem?.quantity && cartItem?.quantity < 1) || !cartItem.quantity) {
				return null;
			}

			return {
				...cartItem,
				orderedCollection: {
					relationTo: cartItem.orderedCollection.relationTo,
					value: extractId(cartItem.orderedCollection.value),
				},
			};
		})
		.filter(Boolean) as CartItem[];

	const discountCode = cart?.discountCode
		? extractId(cart.discountCode as DiscountCode)
		: cart.activeOrder?.discountCode
			? extractId(cart.activeOrder?.discountCode)
			: null;

	const flattenedCart = {
		discountCode,
		discountCodeText: cart?.discountCodeText || cart.activeOrder?.discountCodeText || '',
		chosenInstalmentPaymentsCount: cart?.chosenInstalmentPaymentsCount,
		activeOrder: cart?.activeOrder ? { ...cart.activeOrder, items: cartItems } : null,
		cartItems: cartItems,
		paymentType: cart?.paymentType,
		everyPayPaymentMethodSource: cart?.everyPayPaymentMethodSource,
		paymentDetails: cart?.paymentDetails,
		payerType: cart?.payerType,
		hasChosenInstalmentPayments: cart?.hasChosenInstalmentPayments,
	};

	return flattenedCart;
};

// store cart in local storage
// only sync to activeOrder if activeOrder exists <-- can happen only with failed payment
export const CartProvider = (props: { children: ReactNode }) => {
	const { children } = props;
	const locale = useLocale();
	const t = useTranslations('Cart');

	const [cart, dispatchCart] = useReducer(cartReducer, cartDefaultValues);

	const [total, setTotal] = useState<TotalType>({
		formatted: formatPrice(0, locale, 'EUR'),
		raw: 0,
	});

	const [totalVat, setTotalVat] = useState<TotalType>({
		formatted: formatPrice(0, locale, 'EUR'),
		raw: 0,
	});

	const [totalDiscountCodeReduction, setTotalDiscountCodeReduction] = useState<TotalType>({
		formatted: formatPrice(0, locale, 'EUR'),
		raw: 0,
	});

	const [hasVat, setHasVat] = useState<boolean>(false);

	const hasInitializedLocalStorage = useRef(false);

	const [hasInitializedCart, setHasInitializedCart] = useState(false);
	const [hasInitializedUserCart, setHasInitializedUserCart] = useState(false);
	const [isCartSyncInProgress, setIsCartSyncInProgress] = useState<boolean>(false);
	const [discountCodeError, setDiscountCodeError] = useState<string | null>();
	const [isDiscountCodeLoading, setIsDiscountCodeLoading] = useState<boolean>(false);
	const [canShowInstalmentPayments, setCanShowInstalmentPayments] = useState<boolean>(false);
	const [instalmentPaymentsCalendars, setInstalmentPaymentsCalendars] =
		useState<InstalmentPaymentsWithShares[]>();
	const [
		instalmentSinglePaymentPriceReductionPercentage,
		setInstalmentSinglePaymentPriceReductionPercentage,
	] = useState<Courseversion['instalmentSinglePaymentPriceReductionPercentage']>();
	const [shouldTriggerParticipantFormValidation, setShouldTriggerParticipantFormValidation] =
		useState(false);
	const [shouldTriggerPaymentDetailFormValidation, setShouldTriggerPaymentDetailFormValidation] =
		useState(false);
	const [participantFormState, setParticipantFormState] =
		useState<CartContext['participantFormState']>();

	const [isPaymentRequestInProgress, setIsPaymentRequestInProgress] = useState<boolean>(false);

	const validateLocalStorageCartItems = (cartItems?: OrderCartItems) => {
		if (cartItems && Array.isArray(cartItems) && cartItems.length) {
			return cartItems.filter(cartItem => {
				try {
					cartItemSchema.parse(cartItem);

					return true;
				} catch (_) {
					logToPayload('validate localstorage cart ERR: ', _, 'warn');
					return false;
				}
			});
		}
	};

	// Check local storage for a cart
	// If there is a cart, fetch the cartitems and hydrate the cart
	useEffect(() => {
		if (!hasInitializedLocalStorage.current) {
			const syncCartFromLocalStorage = async () => {
				const localCart = localStorage.getItem('cart');

				const parsedCart: {
					cartItems?: OrderCartItems;
					activeOrder: Order;
					discountCode?: PublicDiscountCode | string;
					discountCodeText?: string;
					chosenInstalmentPaymentsCount?: number;
					hasChosenInstalmentPayments?: boolean;
					paymentDetails?: PaymentDetails;
					payerType?: CartStateType['payerType'];
					paymentType?: CartStateType['paymentType'];
					everyPayPaymentMethodSource?: CartStateType['everyPayPaymentMethodSource'];
				} = JSON.parse(localCart || '{}');
				const validatedCartItems = validateLocalStorageCartItems(parsedCart?.cartItems);

				if (validatedCartItems && validatedCartItems?.length > 0) {
					const activeOrder = parsedCart?.activeOrder;

					setIsCartSyncInProgress(true);
					// remove current order from client if it was already finished (paid,deadline exceeded etc). Most likely some stale data.
					if (activeOrder) {
						const url = `${process.env.NEXT_PUBLIC_SERVER_URL}/api/orders/${activeOrder.id}?locale=${locale}`;
						const res = await fetch(url);
						const data = await res.json();

						if (data?.errors) {
							logToPayload(`Cart provider: order fetch error`, {
								errors: data.errors,
								orderId: activeOrder.id,
							});

							setIsCartSyncInProgress(false);
							return null;
						}

						const isStaleOrder =
							data &&
							!(
								data.status === 'inPreparation' ||
								data.status === 'retryPaymentNeeded'
							);

						if (isStaleOrder) {
							dispatchCart({ type: 'CLEAR_CART' });

							localStorage.removeItem('cart');
							setIsCartSyncInProgress(false);
							return null;
						}
					}

					const initialCart = (
						await Promise.all(
							validatedCartItems.map(async args => {
								if (!args.orderedCollection) {
									setIsCartSyncInProgress(false);
									throw new Error('Internal error');
								}

								const { quantity, orderedCollection } = args;
								const id = extractId(orderedCollection.value);

								if (!quantity || quantity < 1) {
									return null;
								}

								const url = `${process.env.NEXT_PUBLIC_SERVER_URL}/api/${orderedCollection.relationTo}/${id}?locale=${locale}`;
								const res = await fetch(url);
								const data = await res.json();

								if (data?.errors) {
									logToPayload(`Cart contains bad items`, {
										errors: data.errors,
										url,
										courseversionId: id,
									});

									return null;
								}

								if (!data.isAvailableForPurchase) {
									return null;
								}

								const cartItem = {
									...args,
									orderedCollection: { ...orderedCollection, value: data },
								};

								return cartItem;
							}),
						)
					).filter(isTruthy);

					const chosenInstalmentPaymentsCount =
						parsedCart?.chosenInstalmentPaymentsCount ||
						parsedCart?.activeOrder?.chosenInstalmentPaymentsCount;

					dispatchCart({
						payload: {
							cartItems: initialCart,
							activeOrder: parsedCart?.activeOrder,
							chosenInstalmentPaymentsCount,
							hasChosenInstalmentPayments: Boolean(
								chosenInstalmentPaymentsCount && chosenInstalmentPaymentsCount > 1,
							),
							discountCode:
								parsedCart?.discountCode ||
								(parsedCart?.activeOrder?.discountCode as PublicDiscountCode),
							discountCodeText:
								parsedCart?.discountCodeText ||
								parsedCart?.activeOrder?.discountCodeText,
							paymentDetails:
								parsedCart?.paymentDetails ||
								parsedCart?.activeOrder?.paymentDetails,
							paymentType: parsedCart?.paymentType,
							everyPayPaymentMethodSource: parsedCart?.everyPayPaymentMethodSource,
							payerType: parsedCart?.payerType,
						},
						type: 'SET_CART',
					});

					setIsCartSyncInProgress(false);
				}

				hasInitializedLocalStorage.current = true;

				if (!cart.hasInitialSync) {
					dispatchCart({ type: 'SET_INITIAL_SYNC', payload: true });
				}
			};

			void syncCartFromLocalStorage();
		}
	}, []);

	// every time the cart changes,save to local storage
	const syncDependencyCartItems = cart.cartItems?.map(cartItem => {
		return {
			orderedCollectionId: extractId(cartItem.orderedCollection.value),
			quantity: cartItem.quantity,
			participants: cartItem?.participants,
		};
	});
	// sync active order "cart" to payload if active order exists - kind of edge case if payment failed
	const syncCartToPayload = async ({
		flattenedCart,
		isInitialSync,
	}: {
		flattenedCart: CartStateType;
		isInitialSync?: boolean;
	}) => {
		if (!flattenedCart?.activeOrder || typeof flattenedCart?.activeOrder !== 'object') {
			return;
		}

		const activeOrder = flattenedCart?.activeOrder;
		const activeOrderStatus =
			activeOrder && typeof activeOrder === 'object' && activeOrder.status;

		const shouldSkipSync =
			activeOrderStatus &&
			activeOrderStatus !== 'inPreparation' &&
			activeOrderStatus !== 'retryPaymentNeeded' &&
			activeOrderStatus !== 'abandoned';

		// do not sync local data to api if payment is "stuck" or in progress so it wont result in an useless error message for user.
		if (isInitialSync && shouldSkipSync) {
			return;
		}

		setIsCartSyncInProgress(true);

		const req = await fetch(
			`${process.env.NEXT_PUBLIC_SERVER_URL}/api/orders/${activeOrder.id}?locale=${locale}&depth=3`,
			{
				body: JSON.stringify({
					items: flattenedCart.cartItems,
					discountCode: flattenedCart?.discountCode,
					discountCodeText: flattenedCart.discountCodeText,
					chosenInstalmentPaymentsCount: flattenedCart.chosenInstalmentPaymentsCount,
					paymentDetails: flattenedCart.paymentDetails,
				}),
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				method: 'PATCH',
			},
		);

		const res = await req.json();

		if (res.errors) {
			logToPayload('Error while syncing cart to payload: ', { errors: res.errors }, 'warn');

			// @ts-expect-error TODO error typings
			const errorMessageString = res.errors?.map(err => err?.data?.[0]?.message).join('.');

			if (errorMessageString) {
				toast({
					title: errorMessageString,
					variant: 'destructive',
					action: (
						<Button className="p-0 text-white" variant="ghost" size="small">
							<Link href={'/cart'}>{t('go-to-cart')}</Link>
						</Button>
					),
				});

				setIsCartSyncInProgress(false);
				return;
			}
		}

		setIsCartSyncInProgress(false);
	};

	useEffect(() => {
		// wait until we have attempted authentication
		if (!hasInitializedLocalStorage.current) return;
		if (!cart.hasInitialSync) return;

		// ensure that cart items are fully populated, filter out any items that are not
		// this will prevent discontinued products from appearing in the cart
		const flattenedCart = flattenCart(cart);

		try {
			// TODO disable inputs if sync is in progress to prevent DB conflict errors

			if (!isCartSyncInProgress && !isPaymentRequestInProgress) {
				void syncCartToPayload({
					flattenedCart,
					isInitialSync: !hasInitializedUserCart,
				});
			}
		} catch (e) {
			logToPayload('Error while syncing cart to Payload.', { e: e });
		}

		if (cart.activeOrder && !cart.cartItems?.length) {
			dispatchCart({ type: 'SET_ACTIVE_ORDER', payload: null });
		}

		setHasInitializedUserCart(true);
	}, [
		JSON.stringify(syncDependencyCartItems),
		cart.hasInitialSync,
		cart.hasChosenInstalmentPayments,
		cart.chosenInstalmentPaymentsCount,
	]);

	// sync to localstorage
	useEffect(() => {
		if (!hasInitializedLocalStorage.current) return;
		if (!cart.hasInitialSync) return;

		const flattenedCart = flattenCart(cart);

		localStorage.setItem(
			'cart',
			JSON.stringify({ ...flattenedCart, discountCode: cart?.discountCode }),
		);
	}, [
		JSON.stringify(syncDependencyCartItems),
		JSON.stringify(cart?.activeOrder?.items),
		cart.paymentType,
		cart.everyPayPaymentMethodSource,
		JSON.stringify(cart.paymentDetails),
		cart.payerType,
		cart.hasChosenInstalmentPayments,
		cart.chosenInstalmentPaymentsCount,
	]);
	useEffect(() => {
		if (
			!hasInitializedUserCart ||
			isCartSyncInProgress ||
			isDiscountCodeLoading ||
			!cart.discountCodeText
		) {
			return;
		}

		setIsCartSyncInProgress(true);

		const postDiscountCode = async () => {
			setIsDiscountCodeLoading(true);
			setDiscountCodeError(null);
			try {
				const discountCodeResponse = await fetch(
					`${process.env.NEXT_PUBLIC_SERVER_URL}/api/discount-code?locale=${locale}`,
					{
						headers: { 'Content-Type': 'application/json' },
						credentials: 'include',
						cache: 'no-store',
						method: 'POST',
						body: JSON.stringify({
							code: cart.discountCodeText,
							cart: flattenCart(cart),
							orderId: cart.activeOrder ? extractId(cart.activeOrder) : null,
						}),
					},
				)
					?.then(async res => {
						if (!res.ok) {
							setDiscountCodeError(
								t('there-was-an-unexpected-error-please-try-again'),
							);
							return null;
						}
						const json = await res.json();

						if ('error' in json && json.error) {
							dispatchCart({
								type: 'MERGE_CART',
								payload: discountCodeResponse.cart,
							});
							setDiscountCodeError(json.error);
							return null;
						}

						return json;
					})
					?.then(json => json);

				if (discountCodeResponse?.exists === false) {
					if (discountCodeResponse?.status === 'disabled') {
						setDiscountCodeError(t('discount-code-not-available'));
					} else {
						setDiscountCodeError(t('discount-code-does-not-exist'));
					}

					dispatchCart({ type: 'MERGE_CART', payload: discountCodeResponse.cart });
				} else if (
					discountCodeResponse?.exists &&
					discountCodeResponse?.status === 'enabled'
				) {
					// success!
					dispatchCart({ type: 'MERGE_CART', payload: discountCodeResponse.cart });

					const flattenedCart = flattenCart(discountCodeResponse.cart);

					localStorage.setItem(
						'cart',
						JSON.stringify({
							...flattenedCart,
							discountCode: discountCodeResponse?.cart?.discountCode,
						}),
					);
				}
			} catch (err) {
				// @ts-expect-error TODO error typings
				if (err?.message && typeof err?.message === 'string') {
					logToPayload('Discount code error: ', {
						error: err,
						discountCodeText: cart.discountCodeText,
						orderId: cart.activeOrder ? extractId(cart.activeOrder) : null,
					});
					// @ts-expect-error TODO error typings
					setDiscountCodeError(err.message);
				} else {
					setDiscountCodeError(t('there-was-an-unexpected-error-please-try-again'));
				}
			}

			setIsCartSyncInProgress(false);
			setIsDiscountCodeLoading(false);
		};

		void postDiscountCode();
	}, [cart.discountCodeText]);

	// generate instalment payments calendars
	useEffect(() => {
		if (!hasInitializedCart) {
			return;
		}
		// if any cartitem has  instalment payments enabled
		const _canShowInstalmentPayments = Boolean(
			cart?.cartItems?.some(cartItem => {
				if (typeof cartItem.orderedCollection.value === 'string') {
					logToPayload(
						'Bad depth: cartItem orderedCollection is string',
						{ courseversionId: cartItem.orderedCollection.value },
						'warn',
					);
					return false;
				}
				return Boolean(cartItem.orderedCollection.value.isInstalmentPaymentsEnabled);
			}),
		);
		setCanShowInstalmentPayments(_canShowInstalmentPayments);
		// generate instalment payment calendar only if one course if chosen if possible
		if (
			_canShowInstalmentPayments &&
			cart?.cartItems?.length &&
			cart?.cartItems?.length >= 1 &&
			total.raw
		) {
			const courseversion = cart.cartItems[0].orderedCollection.value as Courseversion;
			const courseversionInstalments = courseversion.instalmentPaymentsCalendars;

			const generatedInstalmentPaymentCalendars =
				cart.cartItems.length === 1
					? courseversionInstalments?.map(calendar => {
							const instalmentPaymentsCalendar = generateInstalmentPaymentsCalendar({
								items: cart.cartItems,
								shouldAddFirstPaymentToCalendar: true,
								totalPrice: total.raw,
								shares: calendar.instalmentShareCount,
							});

							return { ...calendar, instalmentPaymentsCalendar };
						})
					: [];
			// add the choice "1" to instalment calendar choice for user
			generatedInstalmentPaymentCalendars?.unshift({
				instalmentShareCount: 1,
				instalmentPaymentsCalendar: null,
			});

			setInstalmentPaymentsCalendars(generatedInstalmentPaymentCalendars);

			if (cart.chosenInstalmentPaymentsCount === 1) {
				setInstalmentSinglePaymentPriceReductionPercentage(
					courseversion.instalmentSinglePaymentPriceReductionPercentage,
				);
			} else {
				setInstalmentSinglePaymentPriceReductionPercentage(null);
			}
		} else if (cart?.cartItems?.length && cart?.cartItems?.length > 1) {
			setInstalmentPaymentsCalendars([
				{ instalmentShareCount: 1, instalmentPaymentsCalendar: null },
			]);
		} else {
			setInstalmentPaymentsCalendars(undefined);
		}
	}, [
		JSON.stringify(syncDependencyCartItems),
		total.raw,
		hasInitializedCart,
		cart.chosenInstalmentPaymentsCount,
		cart.hasChosenInstalmentPayments,
	]);

	const isItemInCart = useCallback(
		(incomingItem: CartItem): boolean => {
			let isInCart = false;
			const { cartItems: itemsInCart } = cart || {};
			if (Array.isArray(itemsInCart) && itemsInCart.length > 0) {
				isInCart = Boolean(
					itemsInCart.find(({ orderedCollection }) => {
						if (!orderedCollection) {
							return false;
						}
						if (!incomingItem?.orderedCollection) {
							return false;
						}
						return (
							(typeof orderedCollection.value === 'string'
								? orderedCollection.value === incomingItem.id
								: orderedCollection?.value.id === incomingItem.id) &&
							orderedCollection.relationTo ===
								incomingItem.orderedCollection.relationTo
						);
					}),
				);
			}
			return isInCart;
		},
		[JSON.stringify(syncDependencyCartItems)],
	);

	useEffect(() => {
		if (hasInitializedLocalStorage.current && hasInitializedUserCart && !hasInitializedCart) {
			setHasInitializedCart(true);
		}
	}, [hasInitializedLocalStorage.current, hasInitializedUserCart]);

	const allParticipantIdsDep = cart.cartItems
		?.flatMap(item => item.participants?.map(p => p.id))
		.join(',');

	// remove deleted participants from participantFormState
	useEffect(() => {
		const allParticipantIds = cart.cartItems?.flatMap(item =>
			item.participants?.map(p => p.id),
		);
		if (!allParticipantIds?.length || !hasInitializedCart) {
			return;
		}

		setParticipantFormState(formState => {
			if (!formState) {
				return formState;
			}
			return Object.fromEntries(
				Object.entries(formState).filter(([id]) => allParticipantIds?.includes(id)),
			);
		});
	}, [allParticipantIdsDep]);

	// this method can be used to add new items AND update existing ones
	const addItemToCart = useCallback((incomingItem: CartItem) => {
		dispatchCart({ payload: incomingItem, type: 'ADD_ITEM' });
	}, []);

	const deleteItemFromCart = useCallback((incomingItem: CartItem) => {
		dispatchCart({ payload: incomingItem, type: 'DELETE_ITEM' });
	}, []);

	const clearDiscountCode = useCallback(() => {
		dispatchCart({ type: 'CLEAR_DISCOUNT_CODE' });

		const activeOrder = cart.activeOrder
			? { activeOrder: { ...cart.activeOrder, discountCode: null, discountCodeText: '' } }
			: {};
		const flattenedCart = flattenCart({
			...cart,
			discountCode: null,
			discountCodeText: '',
			...activeOrder,
		});
		syncCartToPayload({ flattenedCart });
		setDiscountCodeError(null);
		setIsDiscountCodeLoading(false);
		setDiscountCodeText('');

		localStorage.setItem('cart', JSON.stringify(flattenedCart));
	}, [JSON.stringify(cart)]);

	const clearCart = useCallback(() => {
		dispatchCart({ type: 'CLEAR_CART' });
	}, []);

	const setPaymentDetails = useDebounceCallback(
		(paymentDetails: PaymentDetails, isMultiplePayers?: boolean) => {
			dispatchCart({
				payload: { paymentDetails, isMultiplePayers },
				type: 'SET_PAYMENT_DETAILS',
			});
		},
		50,
	);

	const setPayerType = useCallback((payerType: PayerType) => {
		dispatchCart({ payload: payerType, type: 'SET_PAYER_TYPE' });
	}, []);

	const setActiveOrder = useCallback<CartContext['setActiveOrder']>(order => {
		dispatchCart({ payload: order, type: 'SET_ACTIVE_ORDER' });
	}, []);

	const setDiscountCodeText = useCallback<CartContext['setDiscountCodeText']>(
		discountCodeText => {
			dispatchCart({ payload: discountCodeText, type: 'SET_DISCOUNT_CODE_TEXT' });
		},
		[],
	);

	const setHasChosenInstalmentPayments = useCallback((hasChosenInstalmentPayments: boolean) => {
		dispatchCart({
			payload: hasChosenInstalmentPayments,
			type: 'SET_HAS_CHOSEN_INSTALMENT_PAYMENTS',
		});
	}, []);

	const setChosenInstalmentPaymentsCount = useCallback(
		(count: Order['chosenInstalmentPaymentsCount']) => {
			dispatchCart({ payload: count, type: 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT' });
		},
		[],
	);

	const updateParticipant = useCallback<CartContext['updateParticipant']>(
		(participant, courseversionId) => {
			dispatchCart({ payload: { participant, courseversionId }, type: 'UPDATE_PARTICIPANT' });
		},
		[],
	);

	const updateParticipantFormState = useCallback<CartContext['updateParticipantFormState']>(
		(participantId, isValid) => {
			const allParticipantIds = cart.cartItems?.flatMap(item =>
				item.participants?.map(p => p.id),
			);

			setParticipantFormState(formState => {
				const existingParticipantFormState =
					allParticipantIds?.reduce(
						(acc, id) => {
							if (id) {
								acc[id] = formState?.[id] || { isValid: false };
							}
							return acc;
						},
						{} as Record<string, { isValid: boolean }>,
					) || {};

				return {
					...existingParticipantFormState,
					[participantId]: {
						isValid,
					},
				};
			});
		},
		[allParticipantIdsDep],
	);

	const setIsValidPaymentDetails = useCallback<CartContext['setIsValidPaymentDetails']>(
		(isValid, paymentDetailId, errors) => {
			dispatchCart({
				payload: { isValid, paymentDetailId, errors },
				type: 'SET_IS_VALID_PAYMENT_DETAILS',
			});
		},
		[],
	);

	const setPaymentType = useCallback((paymentType: PaymentDetail['paymentType']) => {
		dispatchCart({ payload: paymentType, type: 'SET_PAYMENT_TYPE' });
	}, []);

	const setEveryPayPaymentSource = useCallback(
		(paymentMethodSource: PaymentMethod['source'] | null | undefined) => {
			dispatchCart({
				payload: paymentMethodSource,
				type: 'SET_EVERYPAY_PAYMENT_METHOD_SOURCE',
			});
		},
		[],
	);

	// calculate the new cart total whenever the cart changes
	useEffect(() => {
		if (!hasInitializedLocalStorage) return;

		const calculatedPrices = generateCartOrderTotalPrices({
			items: cart.cartItems,
		});

		if (calculatedPrices) {
			const { total, totalVat, hasVAT, totalDiscountCodeReduction } = calculatedPrices;

			setTotal({ formatted: formatPrice(total, locale, 'EUR'), raw: total });

			setTotalVat({ formatted: formatPrice(totalVat, locale, 'EUR'), raw: totalVat });

			setTotalDiscountCodeReduction({
				formatted: formatPrice(totalDiscountCodeReduction, locale, 'EUR'),
				raw: totalDiscountCodeReduction,
			});

			setHasVat(hasVAT);
		} else {
			logToPayload(`Could not calculate cart prices, cartItems`, {
				cartItems: cart.cartItems?.map(cartItem => ({
					orderedCollectionId: cartItem.orderedCollection?.value
						? extractId(cartItem.orderedCollection.value)
						: null,
				})),
			});
		}
	}, [
		JSON.stringify(syncDependencyCartItems),
		hasInitializedLocalStorage,
		cart.discountCode,
		cart.chosenInstalmentPaymentsCount,
	]);

	return (
		<Context.Provider
			value={{
				addItemToCart,
				cart,
				cartIsEmpty: hasInitializedCart && !arrayHasItems(cart?.cartItems),
				cartTotal: total,
				cartTotalVat: totalVat,
				cartHasVat: hasVat,
				cartTotalDiscountCodeReduction: totalDiscountCodeReduction,
				clearCart,
				deleteItemFromCart,
				hasInitializedCart,
				isItemInCart,
				setPaymentDetails,
				setActiveOrder,
				setDiscountCodeText,
				isCartSyncInProgress,
				discountCodeError,
				isDiscountCodeLoading,
				clearDiscountCode,
				canShowInstalmentPayments,
				instalmentPaymentsCalendars,
				setChosenInstalmentPaymentsCount,
				instalmentSinglePaymentPriceReductionPercentage,
				updateParticipant,
				shouldTriggerParticipantFormValidation,
				setShouldTriggerParticipantFormValidation,
				shouldTriggerPaymentDetailFormValidation,
				setShouldTriggerPaymentDetailFormValidation,
				updateParticipantFormState,
				participantFormState,
				setPayerType,
				setHasChosenInstalmentPayments,
				setPaymentType,
				setEveryPayPaymentSource,
				setIsValidPaymentDetails,
				setIsPaymentRequestInProgress,
				isPaymentRequestInProgress,
			}}
		>
			{children && children}
		</Context.Provider>
	);
};
