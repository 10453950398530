import React, { JSX } from 'react';
import { cn } from '@shadcn/lib/utils';

export const typographySizes = {
	heading: {
		'2xl': '[font-size:_clamp(2.5rem,4vw,5rem)] leading-[1.2]', // Large H1
		xl: '[font-size:_clamp(2.5rem,3.5vw,3.5rem)] leading-[1.2] [letter-spacing:-0.07rem]', // Normal H1
		lg: '[font-size:_clamp(1.5rem,3vw,2.5rem)] leading-[1.2] font-bold', // H2
		md: '[font-size:_clamp(1.125rem,2.5vw,1.5rem)] leading-[1.2] font-bold', // H3
		sm: '[font-size:_clamp(1rem,2vw,1.125rem)] leading-[1.2] font-bold', // H4
	},
	paragraph: {
		xl: '[font-size:_clamp(1.125rem,1.75vw,2rem)] leading-[1.5] font-light',
		lg: '[font-size:_clamp(1.125rem,2.5vw,1.5rem)] leading-[1.5] font-light',
		md: '[font-size:_clamp(0.875rem,2vw,1rem)] leading-[1.5]',
		sm: '[font-size:_clamp(0.75rem,1.5vw,0.875rem)] leading-[1.5]',
	},
};

type ParagraphProps = {
	children: React.ReactNode;
	size?: keyof typeof typographySizes.paragraph;
	className?: string;
	link?: boolean;
	bold?: boolean;
};

type TitleProps = {
	children: React.ReactNode;
	level?: 1 | 2 | 3 | 4;
	largeTitle?: boolean;
	className?: string;
	link?: boolean;
	bold?: boolean;
};

type ListProps = {
	children: React.ReactNode;
	ordered?: boolean;
	className?: string;
};

type ListItemProps = {
	children: React.ReactNode;
	value?: number;
	className?: string;
};

type OverlineProps = {
	children: React.ReactNode;
	className?: string;
};

const Paragraph: React.FC<ParagraphProps> = ({ size = 'md', bold, children, className = '' }) => {
	return (
		<p
			className={cn(
				typographySizes.paragraph[size],
				'text-ebs-blue-500',
				bold ? 'font-bold' : 'font-normal',
				className,
			)}
		>
			{children}
		</p>
	);
};

const Title: React.FC<TitleProps> = ({
	level = 3,
	bold,
	largeTitle,
	children,
	className = '',
	link,
}) => {
	let sizeKey: keyof typeof typographySizes.heading =
		level === 2 ? 'lg' : level === 3 ? 'md' : 'sm';

	if (level === 1) {
		sizeKey = largeTitle ? '2xl' : 'xl';
	}

	const Tag = `h${level}` as keyof JSX.IntrinsicElements;

	return (
		<Tag
			className={cn(
				typographySizes.heading[sizeKey],
				bold ? 'font-bold' : 'font-normal',
				link && 'cursor-pointer hover:underline',
				'text-ebs-blue-500 inline',
				className,
			)}
		>
			{children}
		</Tag>
	);
};

const List: React.FC<ListProps> = ({ children, ordered = false, className }) => {
	const Tag = ordered ? 'ol' : 'ul';
	return (
		<Tag
			className={cn(
				'text-ebs-blue-500 ml-4 flex w-fit list-outside flex-col space-y-1',
				ordered ? 'list-decimal' : 'list-disc',
				className,
			)}
		>
			{children}
		</Tag>
	);
};

const ListItem: React.FC<ListItemProps> = ({ children, value, className }) => {
	return (
		<li className={cn(className)} value={value}>
			{children}
		</li>
	);
};

const Overline: React.FC<OverlineProps> = ({ children, className = '' }) => {
	return (
		<span
			className={cn(
				'text-ebs-golden-500 text-[12px] leading-[1.15] font-bold tracking-[0.72] uppercase',
				className,
			)}
		>
			{children}
		</span>
	);
};

const Typography = { Paragraph, Title, List, ListItem, Overline };

export { Paragraph, Title, List, ListItem, Overline };

export default Typography;
