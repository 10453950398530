import { Button as UIButton } from '@headlessui/react';
import clsx from 'clsx';
import ChevronRightIcon from '../../_icons/Chevron-right';
import { ReactNode, ButtonHTMLAttributes } from 'react';
import Spinner from '@/app/(frontend)/_icons/Spinner';

type ButtonProps = {
	children: ReactNode;
	size?: 'lg' | 'md' | 'sm' | 'sm-fixed';
	variant?: 'solid' | 'outlined' | 'ghost' | 'linkLabel' | 'gold';
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	chevronRight?: boolean;
	className?: string;
	loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const sizeStyles = {
	lg: 'py-3 px-6 text-xl',
	md: 'py-1 px-6 text-lg',
	sm: 'py-0 px-2 text-base h-8',
	'sm-fixed': 'py-0 px-2 text-base h-8 min-w-32',
} as const;

const variantStyles = {
	solid: 'bg-ebs-blue-900 text-white hover:bg-ebs-blue-500 active:bg-blue-800',
	outlined: 'border border-ebs-blue-500 text-ebs-blue-900 hover:bg-ebs-blue-200',
	ghost: 'text-ebs-blue-500 font-mulish font-semibold underline underline-offset-[3px] hover:text-ebs-golden-500',
	linkLabel: 'border border-ebs-golden-500 text-ebs-blue-900 rounded-sm hover:bg-ebs-golden-100',
	gold: 'bg-ebs-golden-500 text-white hover:bg-ebs-golden-600 active:bg-ebs-golden-700 rounded-xs',
} as const;

const chevronColor = {
	solid: '#ffffff', // ebs-white
	outlined: '#1D3A5F', // ebs-blue-900
	ghost: '#4F85C1', // ebs-blue-500
	linkLabel: '#D1AD73', // ebs-golden-500
	gold: '#ffffff',
} as const;

const spinnerColor = {
	solid: '#ffffff', // ebs-white
	outlined: '#1D3A5F', // ebs-blue-900
	ghost: '#4F85C1', // ebs-blue-500
	linkLabel: '#D1AD73', // ebs-golden-500
	gold: '#ffffff',
} as const;

const Button: React.FC<ButtonProps> = ({
	children,
	size = 'lg',
	variant = 'solid',
	loading = false,
	leftIcon,
	rightIcon,
	chevronRight,
	className,
	...props
}) => {
	const baseStyles =
		'inline-flex items-center justify-center rounded-sm transition-all duration-200 focus:outline-none cursor-pointer gap-2 disabled:cursor-not-allowed';

	return (
		<UIButton
			className={clsx(baseStyles, sizeStyles[size], variantStyles[variant], className)}
			{...props}
			disabled={loading || props.disabled}
		>
			{leftIcon && <span className="h-5 w-5">{leftIcon}</span>}
			{children}
			{chevronRight && !loading && <ChevronRightIcon color={chevronColor[variant]} />}
			{loading && (
				<span className="animate-spin">
					<Spinner color={spinnerColor[variant]} />
				</span>
			)}
			{!loading && rightIcon && <span className="h-6 w-6">{rightIcon}</span>}
		</UIButton>
	);
};

export default Button;
