'use client';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { useCart } from '../../_providers/Cart';
import { Button } from '../ButtonOld';
import { PaymentDetail, PaymentDetails } from '../../_providers/Cart/reducer';
import { OrderPayerForm } from './OrderPayerForm';
import { Input } from '@shadcn/components/ui/input';
import _ from 'lodash';
import { formatPrice } from '../../_utilities/format-price';
import ChevronRight from '../../_icons/Chevron-right';

import { AttentionBox } from '../attention-box';
import { OrderCreationErrorType } from '../../_utilities/typescript';
import { List, ListItem, Paragraph, Title } from '@/app/(frontend)/_components/Typography';
import { RadioToggle } from '@/app/(frontend)/_components/radio-toggle';
import { Info } from 'lucide-react';
import { BackToCart } from '@/app/(frontend)/[locale]/cart/_Components/BackToCart';
import { Link } from '@/navigation';

export const MULTIPLE_PAYER_FIRST_INTERNAL_ID = 'multiple-payers-1-paymentDetail';
export const MULTIPLE_PAYER_SECOND_INTERNAL_ID = 'multiple-payers-2-paymentDetail';

export const MultiplePayers: React.FC<{
	onSubmit?: (data: PaymentDetails) => unknown;
	globalError?: string;
	errors?: OrderCreationErrorType[];
	isSubmitInProgress?: boolean;
}> = ({ onSubmit, globalError, errors, isSubmitInProgress }) => {
	const { setPaymentDetails, cart } = useCart();
	const t = useTranslations('Checkout');

	const multiplePayersDisabled =
		(cart.cartItems?.length && cart.cartItems?.length > 1) ||
		cart.cartItems?.some(item => item.quantity && item.quantity > 1);

	const paymentDetails = cart.paymentDetails || cart?.activeOrder?.paymentDetails;
	const initialFirstPayer = paymentDetails?.find(
		paymentDetail => paymentDetail.id === MULTIPLE_PAYER_FIRST_INTERNAL_ID,
	);
	const initialSecondPayer = paymentDetails?.find(
		paymentDetail => paymentDetail.id === MULTIPLE_PAYER_SECOND_INTERNAL_ID,
	);
	const firstPayerType = initialFirstPayer?.payerType || 'personal';
	const secondPayerType = initialSecondPayer?.payerType || 'personal';

	const setMultiplePayerDetails = (paymentDetail: PaymentDetail) => {
		if (!Number.isFinite(paymentDetail.instalmentShare)) {
			paymentDetail.instalmentShare = 0;
		}

		setPaymentDetails([paymentDetail], true);
	};

	if (multiplePayersDisabled) {
		return (
			<AttentionBox
				showIcon
				iconType="info"
				text={t('multiple-payers-too-many-slots', { email: 'koolitus@ebs.ee' })}
			>
				<br />
				<br />
				<Link href="/cart">
					<Paragraph className="underline">{t('backToCart')}</Paragraph>
				</Link>
			</AttentionBox>
		);
	}

	return (
		<Fragment>
			<div className="mb-8 space-y-4">
				{firstPayerType && (
					<Fragment>
						<OrderPayerForm
							isSubmitInProgress={false}
							paymentDetailId={MULTIPLE_PAYER_FIRST_INTERNAL_ID}
							onChange={values => {
								setMultiplePayerDetails({
									...values,
									id: MULTIPLE_PAYER_FIRST_INTERNAL_ID,
								});
							}}
							payerType={firstPayerType}
							key={`form-${MULTIPLE_PAYER_FIRST_INTERNAL_ID}-${firstPayerType}`}
							mode="onChange"
							error={globalError}
							errors={errors}
						/>
					</Fragment>
				)}
			</div>

			<div className="mb-8 space-y-4">
				{secondPayerType && (
					<Fragment>
						<OrderPayerForm
							isSubmitInProgress={false}
							paymentDetailId={MULTIPLE_PAYER_SECOND_INTERNAL_ID}
							onChange={values => {
								setMultiplePayerDetails({
									...values,
									id: MULTIPLE_PAYER_SECOND_INTERNAL_ID,
								});
							}}
							payerType={secondPayerType}
							mode="onChange"
							key={`form-${MULTIPLE_PAYER_SECOND_INTERNAL_ID}-${secondPayerType}`}
							error={globalError}
							errors={errors}
						/>
					</Fragment>
				)}
			</div>

			<div className="bg-ebs-blue-200 flex-row p-4">
				<Info className="text-ebs-blue-500 mr-4 inline-flex size-6" />
				<div className="inline-flex flex-col">
					<Paragraph className="mb-4">{t('multiple-payers-info')}</Paragraph>
					<List>
						<ListItem>
							{t('slot-booked-for', {
								slotsCount: 2,
								bookedDaysCount: 7,
							})}
						</ListItem>
						<ListItem>{t('multiple-payers-info-2')}</ListItem>
						<ListItem>{t('multiple-payers-info-3')}</ListItem>
					</List>
				</div>
			</div>
		</Fragment>
	);
};

export default MultiplePayers;
