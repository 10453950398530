import Button from '@/app/(frontend)/_components/Button';
import React from 'react';

export type RadioToggleOption<T extends string> = {
	value: T;
	label: string;
};

type RadioToggleProps<T extends string> = {
	options: RadioToggleOption<T>[];
	value?: T | null;
	onChange: (value: T) => void;
	className?: string;
};

export const RadioToggle = <T extends string>(props: RadioToggleProps<T>) => {
	const { options, value, onChange, className = '' } = props;

	return (
		<div
			className={`bg-ebs-blue-100 flex w-fit flex-row flex-wrap items-start gap-1 rounded p-1 ${className}`}
		>
			{options.map(option => (
				<Button
					size="sm-fixed"
					variant={value === option.value ? 'gold' : 'ghost'}
					key={`toggle-${option.value}`}
					onClick={() => onChange(option.value)}
					style={{ textDecoration: 'none' }}
				>
					{option.label}
				</Button>
			))}
		</div>
	);
};
